import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { Image } from 'cloudinary-react';
import cx from 'lib/cx';

import { useRouter } from 'next/router';
import CampaignPageEventAddress from './CampaignPageEventAddress';
import CampaignPageEventInfo from './CampaignPageEventInfo';

const GET_CAMPAIGN_EVENT = gql`
  query GetCampaignEvent($id: String!, $widgetId: String!, $isExisting: Boolean!) {
    findCampaigns(id: $id) {
      event {
        id
        startDate
        endDate
        location
        address1
        address2
        country
        stateProv
        postalCode
        city
        time
      }

      widgets(where: { id: $widgetId }) @include(if: $isExisting) {
        id
        title
        config
      }
    }
  }
`;

const CampaignPageEvent = ({ id, className }) => {
  const router = useRouter();
  const { id: campaignId } = router.query;

  const { data } = useQuery(GET_CAMPAIGN_EVENT, {
    variables: { id: campaignId, widgetId: id, isExisting: !!id },
  });

  const campaign = data?.findCampaigns[0];
  const eventDetails = campaign?.event;
  const eventWidget = campaign?.widgets?.[0];

  if (!eventWidget) return null;
  return (
    <div className={cx('py-16 bg-white', className)}>
      <div className="container max-w-7xl">
        <section
          className={`flex flex-col gap-8 md:flex-row md:justify-center md:items-center md:gap-16`}
        >
          <CampaignPageEventInfo eventWidget={eventWidget} eventDetails={eventDetails} />

          {!eventWidget.config?.image && <CampaignPageEventAddress eventDetails={eventDetails} />}

          {eventWidget.config?.image && <ImgWrapper value={eventWidget.config.image} />}
        </section>
      </div>
    </div>
  );
};

const ImgWrapper = ({ value }) => {
  return (
    <figure className="relative overflow-hidden w-full h-[22rem] md:h-[30rem] rounded-lg">
      <Image publicId={value} width={200} alt="raffle-img" className="w-full h-full object-cover" />
    </figure>
  );
};

CampaignPageEvent.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CampaignPageEvent.defaultProps = {
  className: '',
};

export default CampaignPageEvent;
